import React, { useContext, useState, useEffect, useRef } from "react";
import "./Attendance.css";
import { EmplContext } from "../Login_Page/Login_Page/LoginPage";
import configData from "../../config.json";
import LoadingComponent from "../loading_component";
import { IoChevronBackOutline } from "react-icons/io5";
import AttendanceLoading from "./AttendanceLoading";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import eventEmitter from "../../Eventemitter";
import { useLocation } from "react-router-dom";

function Attendance({ sharedFunction, setSharedFunction }) {
  const location = useLocation();
  const divRef = useRef(null);
  const { resultData } = useContext(EmplContext);
  const employee_id = resultData.employee_id || 0;
  const [Attendances, setAttendances] = useState([]);
  const [displayedAttendance, setDisplayedAttendance] = useState([]);
  const [attendanceCounts, setAttendanceCounts] = useState({
    leaveCount: 0.0,
    autoleaveCount: 0.0,
    presentCount: 0,
    absentCount: 0,
  });
  const [presentDaysData, setPresentDaysData] = useState([]);
  const [absentDaysData, setAbsentDaysData] = useState([]);
  const [leaveDaysData, setLeaveDaysData] = useState([]);
  const [autoleaveDaysData, setAutoleaveDaysData] = useState([]);
  const [showDaysData, setShowDaysData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("1");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [activeBox, setActiveBox] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const [load, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [show, setShow] = useState(false);
  const [showleave, setShowLeave] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const divRef_leave = useRef(null);
  const [formData, setFormData] = useState({
    remarks: "",
    reason: "",
    rec_id: "",
  });
  const [fetchexception, setFetchexception] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowLeave(false);
  };

  const handleShow = (row) => {
    setSelectedRow(row);
    setFormData({
      rec_id: row[0][2],
    });
    setShow(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (sharedFunction) {
      handleClick();
      setSharedFunction(null);
    }
  }, [sharedFunction, setSharedFunction]);

  const handleClick = () => {
    if (divRef.current) {
      divRef.current.click();
    }
  };

  useEffect(() => {
    setLoading(true);
  }, []);

  const handleBoxClick = (boxId) => {
    setActiveBox(boxId === activeBox ? null : boxId);
  };

  const fetchAttendance = async () => {
    // console.log("called")
    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey };
      const response = await fetch(`${configData.SERVER_URL}/myattendances`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result)
      if (result.success === true) {
        setAttendances(result.attendance_yearly);
        setLoading(false);
      }else{
        console.log("result ===", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Check if the user navigated to this page via the email link
    const params = new URLSearchParams(location.search);
    const fromEmail = params.get("fromEmail");

    if (fromEmail === "true") {
      // console.log("called emitter")
      // eventEmitter.emit("clickAutoleave");
      ClickAutoleave();
    }
  }, [location]);

  useEffect(() => {
    if (activeBox === null) {
      setShowDaysData([...displayedAttendance]);
    } else {
      switch (activeBox) {
        case "box1":
          setShowDaysData([...presentDaysData]);
          break;
        case "box2":
          setShowDaysData([...absentDaysData]);
          break;
        case "box3":
          setShowDaysData([...leaveDaysData]);
          break;
        case "box4":
          setShowDaysData([...autoleaveDaysData]);
          break;
        default:
          setShowDaysData([...displayedAttendance]);
          break;
      }
    }
  }, [
    activeBox,
    displayedAttendance,
    presentDaysData,
    absentDaysData,
    leaveDaysData,
    autoleaveDaysData,
  ]);

  // console.log("Attendances===========", Attendances);
  // console.log("showDaysData===========", showDaysData);
  // console.log("activeBox===========", activeBox);

  const shouldShowRaiseColumn = showDaysData.every(
    (leave) => leave[7].length !== 0
  );

  const Shouldremarksshown = showDaysData.every(
    // console.log("")
    (leave) => leave[7]?.[0]?.[4] === ""
  );

  // console.log("Shouldremarksshown=======",Shouldremarksshown)
  useEffect(() => {
    const autoleaverows = showDaysData.filter((leave) => leave[7].length !== 0);
    // console.log("autoleaverows===========", autoleaverows, typeof autoleaverows);
    const countRows = autoleaverows.filter((leave) => {
      // console.log("leave============",leave);
      return leave[7][0][3] === "Ex-draft" || leave[7][0][3] === "Ex-reject";
    }).length;
    // console.log("countRows===========", countRows, typeof countRows);
    eventEmitter.emit("countUpdated", countRows);
  }, [showDaysData]);

  const RaiseRequest = async (e) => {
    e.preventDefault();

    // console.log("ev ", e);
    if (fetchexception) return; // Prevent multiple requests

    setFetchexception(true); // Set fetchleave to true to indicate the request is in progress

    try {
      const secretKey = configData.SECRET_Key;
      const requestData = { employee_id, secretKey, formData };
      const response = await fetch(`${configData.SERVER_URL}/raiseRequest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      const result = JSON.parse(data.result);
      // console.log("result", result);
      if (result.success === true) {
        // Swal.fire({
        //   icon: "success",
        //   title: result.message,
        // });
        // if (divRef_leave.current) {
        //   divRef_leave.current.click();
        // }
        window.location.reload();
      } else {
        Swal.fire({
          icon: "error",
          title: result.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // fetchAttendance();
      setFetchexception(false); // Reset fetchleave to false after the request is completed
      setFormData({
        remarks: "",
        reason: "",
        rec_id: "",
      });
    }
  };

  function getFirstDateOfLastNMonths(n) {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() - n + 1;
    const date = 1;
    if (month <= 0) {
      return new Date(year - 1, 12 + month, date);
    } else {
      return new Date(year, month, date);
    }
  }

  function getAllCounts(displayedAttendance) {
    let newAttendanceCounts = {
      leaveCount: 0.0,
      autoleaveCount: 0.0,
      presentCount: 0.0,
      absentCount: 0.0,
    };

    if (displayedAttendance) {
      const presentData = [];
      const absentData = [];
      const leaveData = [];
      const autoleaveData = [];
      displayedAttendance.forEach((item) => {
        if (item[4] === "Present") {
          presentData.push(item);
          newAttendanceCounts.presentCount =
            newAttendanceCounts.presentCount + 1;
        }
        if (item[5] == "unapproved" || item[4] == "Absent") {
          absentData.push(item);
          newAttendanceCounts.absentCount =
            newAttendanceCounts.absentCount + item[6];
        }
        if (item[4] === "Leave" || item[4] == "Absent") {
          leaveData.push(item);
          newAttendanceCounts.leaveCount =
            newAttendanceCounts.leaveCount + item[6];
        }
        // if (item[4] === "Leave-Fullday") {
        //     leaveData.push(item);
        //     newAttendanceCounts.leaveCount = newAttendanceCounts.leaveCount + 1;
        // }
        // if (item[4] === "Autoleave-Halfday") {
        //     autoleaveData.push(item);
        //     newAttendanceCounts.autoleaveCount = newAttendanceCounts.autoleaveCount + 0.5;
        // }
        if (item[4] === "Autoleave") {
          autoleaveData.push(item);
          newAttendanceCounts.autoleaveCount =
            newAttendanceCounts.autoleaveCount + 1;
        }
      });
      setPresentDaysData(presentData);
      setAbsentDaysData(absentData);
      setLeaveDaysData(leaveData);
      setAutoleaveDaysData(autoleaveData);
    }

    setAttendanceCounts(newAttendanceCounts);
  }
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "range") {
    } else {
      // let FirstDateOfLastNplus1Months;
      let FirstDateOfLastNplus1Months = getFirstDateOfLastNMonths(1);
      let FirstDateOfLastNMonths;

      if (value === "3") {
        FirstDateOfLastNMonths = getFirstDateOfLastNMonths(4);
      } else if (value === "6") {
        FirstDateOfLastNMonths = getFirstDateOfLastNMonths(7);
      } else if (value === "2") {
        FirstDateOfLastNMonths = getFirstDateOfLastNMonths(value);
      } else if (value === "1") {
        FirstDateOfLastNMonths = getFirstDateOfLastNMonths(value);
        FirstDateOfLastNplus1Months = getFirstDateOfLastNMonths(value - 1);
      }
      const displayedAttendance = FirstDateOfLastNMonths
        ? Attendances.filter((attendance) => {
            const attendanceDateString = attendance[0];
            const [attendanceDay, attendanceMonth, attendanceYear] =
              attendanceDateString.split("-");
            const attendanceDate = new Date(
              `${attendanceMonth}-${attendanceDay}-${attendanceYear}`
            );
            return (
              attendanceDate >= FirstDateOfLastNMonths &&
              attendanceDate < FirstDateOfLastNplus1Months
            );
          })
        : Attendances;
      setDisplayedAttendance(displayedAttendance);
      getAllCounts(displayedAttendance);
      // console.log("displayedAttendance",displayedAttendance);
    }
  };

  useEffect(() => {
    fetchAttendance();
    // handleChange({ target: { value: selectedOption } });
  }, [employee_id]);

  useEffect(() => {
    if (Attendances.length > 0 && selectedOption === "1") {
      handleChange({ target: { value: "1" } });
    }
  }, [Attendances, selectedOption]);

  const handleSubmit = () => {
    // console.log("Date range submitted:", dateFrom, dateTo);
    if (dateFrom && dateTo) {
      const fromDate = new Date(dateFrom);
      fromDate.setHours(0, 0, 0, 0);
      const toDate = new Date(dateTo);
      // console.log("fromDate",fromDate)
      const filteredAttendance = Attendances.filter((attendance) => {
        const attendanceDateString = attendance[0];
        const [attendanceDay, attendanceMonth, attendanceYear] =
          attendanceDateString.split("-");
        const attendanceDate = new Date(
          `${attendanceMonth}-${attendanceDay}-${attendanceYear}`
        );
        // console.log("attendanceDate",attendanceDate)
        return attendanceDate >= fromDate && attendanceDate <= toDate;
      });
      setDisplayedAttendance(filteredAttendance);
      getAllCounts(filteredAttendance);
    }
  };

  const divRef_autoleave = useRef();

  const ClickAutoleave = () => {
    if (divRef_autoleave.current) {
      divRef_autoleave.current.click();
    }
  };

  useEffect(() => {
    const handleClickAutoleave = () => {
      ClickAutoleave();
    };

    eventEmitter.on("clickAutoleave", handleClickAutoleave);

    // Cleanup listener on component unmount
    return () => {
      eventEmitter.off("clickAutoleave", handleClickAutoleave);
    };
  }, []);

  function showFilterS() {
    setShowFilter(!showFilter);
  }

  // console.log("resultData.length", resultData.length)
  return (
    <>
      {load ? (
        <div className="main-panel">
          <AttendanceLoading />
        </div>
      ) : (
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="ep_content">
              <div className="row">
                <div className="col-md-12">
                  <div className="das-filter">
                    <div className="das-filtitle" style={{ gap: "10px" }}>
                      <IoChevronBackOutline
                        className="backbutton"
                        onClick={() => setActiveBox(null)}
                      />
                      <div className="showFilter-btn" onClick={showFilterS}>
                        <img
                          src="filter.jpg"
                          style={{
                            height: 15,
                            margin: "7px 0px 10px 16px",
                          }}
                        />
                        <p
                          htmlFor="options"
                          style={{
                            marginBottom: "0px",
                            lineHeight: "29px",
                          }}
                        >
                          Filter
                        </p>
                      </div>
                    </div>
                    <div
                      className={`das-filtitle mt-3 ${
                        showFilter ? "show-filter" : ""
                      }`}
                    >
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="currentMonth"
                          name="filterOption"
                          value="1"
                          checked={selectedOption === "1"}
                          onChange={handleChange}
                        />
                        <label htmlFor="currentMonth">Current Month</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="lastMonth"
                          name="filterOption"
                          value="2"
                          checked={selectedOption === "2"}
                          onChange={handleChange}
                        />
                        <label htmlFor="lastMonth">Last Month</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="lastThreeMonths"
                          name="filterOption"
                          value="3"
                          checked={selectedOption === "3"}
                          onChange={handleChange}
                        />
                        <label htmlFor="lastThreeMonths">Last 3 Months</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="lastSixMonths"
                          name="filterOption"
                          value="6"
                          checked={selectedOption === "6"}
                          onChange={handleChange}
                        />
                        <label htmlFor="lastSixMonths">Last 6 Months</label>
                      </div>
                      <div className="radio-option">
                        <input
                          type="radio"
                          id="customRange"
                          name="filterOption"
                          value="range"
                          checked={selectedOption === "range"}
                          onChange={handleChange}
                        />
                        <label htmlFor="customRange">Custom Range</label>
                      </div>
                    </div>
                    <div
                      className={`das-filtitle ${
                        showFilter ? "show-filter" : ""
                      }`}
                    >
                      {selectedOption === "range" && (
                        <div
                          className="mt-3"
                          style={{ display: "flex", gap: "20px" }}
                        >
                          <label htmlFor="dateFrom">Date From:</label>
                          <input
                            type="date"
                            id="dateFrom"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                            // onChange={handleDateFromChange}
                          />
                          <label htmlFor="dateTo">Date To:</label>
                          <input
                            type="date"
                            id="dateTo"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                            // onChange={handleDateToChange}
                          />
                          <button onClick={handleSubmit}>Submit</button>
                        </div>
                      )}
                    </div>
                    {/* <div className="das-lastm">
                                    {selectedOption && (
                                        <p>You selected: {getSelectedText(selectedOption)}</p>
                                    )}
                                </div> */}
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                      <div
                        className={`box ${
                          activeBox === "box1" ? "active" : ""
                        }`}
                        onClick={() => handleBoxClick("box1")}
                      >
                        <div className="dash-count das">
                          <div className="dash-counts">
                            <h4 className="dash-title">Present</h4>
                          </div>
                          <h4 className="dash-cunt dac">
                            {attendanceCounts.presentCount || 0}
                          </h4>
                          {/* <div className="dash-imgs">
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                      <div
                        className={`box ${
                          activeBox === "box3" ? "active" : ""
                        }`}
                        onClick={() => handleBoxClick("box3")}
                        ref={divRef}
                      >
                        <div className="dash-count das2">
                          <div className="dash-counts">
                            <h4 className="dash-title">Leave</h4>
                          </div>
                          <div className="dash-imgs">
                            <h4 className="dash-cunt dac2">
                              {attendanceCounts.leaveCount || 0}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                      <div
                        className={`box ${
                          activeBox === "box2" ? "active" : ""
                        }`}
                        onClick={() => handleBoxClick("box2")}
                      >
                        <div className="dash-count das1">
                          <div className="dash-counts">
                            <h4 className="dash-title">UnApproved</h4>
                          </div>
                          <div className="dash-imgs">
                            <h4 className="dash-cunt dac1">
                              {attendanceCounts.absentCount || 0}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                      <div
                        className={`box ${
                          activeBox === "box4" ? "active" : ""
                        }`}
                        onClick={() => handleBoxClick("box4")}
                        ref={divRef_autoleave}
                      >
                        <div className="dash-count das3">
                          <div className="dash-counts">
                            <h4 className="dash-title">Auto-Leave</h4>
                          </div>
                          <div className="dash-imgs">
                            <h4 className="dash-cunt dac3">
                              {attendanceCounts.autoleaveCount || 0}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="atable">
                      <table
                        id="basicTable"
                        // className="basic-table"
                        className={`basic-table ${
                          activeBox === "box2" || activeBox === "box3"
                            ? "table-mini"
                            : ""
                        }`}
                      >
                        {/* <colgroup>
                            <col style={{ width: 200 }} />
                            <col style={{ width: 200 }} />
                            <col style={{ width: 205 }} />
                            <col style={{ width: 200 }} />
                            <col style={{ width: 150 }} />
                        </colgroup> */}
                        <thead className="attendanceDataTableHead">
                          <tr
                            role="row"
                            //   style={{ background: "#14b49c" }}
                            className={`${
                              activeBox === null ? "allTypeData" : ""
                            } ${
                              activeBox === "box1" ? "presentTypeData" : ""
                            } ${activeBox === "box2" ? "absentTypeData" : ""} ${
                              activeBox === "box3" ? "leaveTypeData" : ""
                            } ${
                              activeBox === "box4" ? "autoLeaveTypeData" : ""
                            }`}
                          >
                            <th className="center">
                              <span> Date </span>
                              <span />
                            </th>
                            {activeBox === "box2" || activeBox === "box3" ? (
                              <></>
                            ) : (
                              <>
                                <th className="center">
                                  <span> Check-In </span>
                                  <span />
                                </th>
                                <th className="center">
                                  <span> Check-Out </span>
                                  <span />
                                </th>
                                <th className="center">
                                  <span> Working Hours </span>
                                  <span />
                                </th>
                                <th className="center">
                                  <span> Out Hours </span>
                                  <span />
                                </th>
                                <th className="center">
                                  <span> Productive Hours </span>
                                  <span />
                                </th>
                              </>
                            )}

                            <th className="center">
                              <span> Status </span>
                              <span />
                            </th>
                            {shouldShowRaiseColumn && (
                              <>
                                <th className="center">
                                  <span> Raise </span>
                                  <span />
                                </th>
                                {!Shouldremarksshown && (
                                  <>
                                    <th className="center">
                                      <span>Raise Reason </span>
                                      <span />
                                    </th>
                                    <th className="center">
                                      <span>Manager Remarks </span>
                                      <span />
                                    </th>
                                  </>
                                )}
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {showDaysData.slice().reverse().map((attendance, index) => (
                            <tr
                              key={index}
                              className={`${
                                activeBox === null ? "allTypeData" : ""
                              } ${
                                activeBox === "box1" ? "presentTypeData" : ""
                              } ${
                                activeBox === "box2" ? "absentTypeData" : ""
                              } ${
                                activeBox === "box3" ? "leaveTypeData" : ""
                              } ${
                                activeBox === "box4" ? "autoLeaveTypeData" : ""
                              }`}
                            >
                              <td> {attendance[0]}</td>
                              {activeBox === "box2" || activeBox === "box3" ? (
                                <></>
                              ) : (
                                <>
                                  <td> {attendance[1] || "- -"}</td>
                                  <td> {attendance[2] || "- -"}</td>
                                  <td> {attendance[3] || "- -"} </td>
                                  <td> {attendance[8] || "- -"} </td>
                                  <td> {attendance[9] || "- -"} </td>
                                </>
                              )}

                              <td className="attendance-tags">
                                <span
                                  // className={` ${attendance[4] === '' ? 'tag-Weekend' : ''}`}
                                  className={` ${
                                    attendance[4].startsWith("Leave")
                                      ? attendance[5].startsWith("approved")
                                        ? "tag-Leave"
                                        : "tag-leave-unapporved"
                                      : attendance[4].startsWith("Autoleave")
                                      ? "tag-AutoLeave"
                                      : attendance[4] === "Present"
                                      ? "tag-Present"
                                      : attendance[4] === "Holiday"
                                      ? "tag-Holiday"
                                      : attendance[4] === "Absent"
                                      ? "tag-leave-unapporved"
                                      : "tag-Weekend"
                                  }`}
                                >
                                  {attendance[4]
                                    ? `${attendance[4]} ${attendance[5]}`
                                    : "Weekend"}
                                </span>
                              </td>
                              {/* {shouldShowRaiseColumn && (
                                <td>
                                  {" "}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleShow(attendance[7]);
                                    }}
                                    className="btn btn-primary"
                                  >
                                    Raise
                                  </button>
                                </td>
                              )} */}
                              {shouldShowRaiseColumn && (
                                <>
                                  <td>
                                    {/* {attendance[7][0][3]} */}
                                    {attendance[7][0][3] === "Ex-draft" ||
                                    attendance[7][0][3] === "Ex-reject" ? (
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleShow(attendance[7]);
                                        }}
                                        className="btn btn-primary"
                                      >
                                        Raise
                                      </button>
                                    ) : attendance[7][0][3] === "Ex-raised" ? (
                                      <span className="exception-raised op_p">
                                        Exception raised
                                      </span>
                                    ) : attendance[7][0][3] ===
                                      "Ex-approved" ? (
                                      <span className="exception-approved op_a">
                                        Exception approved
                                      </span>
                                    ) : null}
                                  </td>
                                  {!Shouldremarksshown && (
                                    <>
                                      <td>{attendance[7][0][4]}</td>
                                      <td>{attendance[7][0][5]}</td>
                                    </>
                                  )}
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <form className="raise-request-form">
                        <Modal
                          show={show}
                          onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                          centered
                          className="raise-request-modal"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Raise Request</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="raise-request-form">
                              <div className="form-left">
                                <div className="form-group">
                                  <label htmlFor="request-name">Name</label>
                                  <input
                                    id="request-name"
                                    type="text"
                                    name="name"
                                    value={selectedRow ? selectedRow[0][0] : ""}
                                    readOnly
                                  />
                                </div>

                                <div className="form-group">
                                  <label htmlFor="request-desp">
                                    Description
                                  </label>
                                  <textarea
                                    id="request-desp"
                                    type="text"
                                    name="description"
                                    value={selectedRow ? selectedRow[0][1] : ""}
                                    readOnly
                                    rows="3"
                                  />
                                </div>
                              </div>

                              <div className="form-right">
                                <div className="form-group">
                                  <label htmlFor="request-reason">Reason</label>
                                  <select
                                    id="request-reason"
                                    name="reason"
                                    value={formData.reason}
                                    onChange={handleInputChange}
                                  >
                                    <option value=""></option>
                                    <option value="technical-issue">
                                      Technical Issue
                                    </option>
                                    <option value="planned-meetings">
                                      Planned Meetings(externals)
                                    </option>
                                    <option value="others">Others</option>
                                  </select>
                                </div>

                                <div className="form-group">
                                  <label htmlFor="request-remarks">
                                    Remarks
                                  </label>
                                  <textarea
                                    id="request-remarks"
                                    type="text"
                                    name="remarks"
                                    value={formData.remarks || ""}
                                    onChange={handleInputChange}
                                    rows="3"
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              ref={divRef_leave}
                              variant="secondary"
                              onClick={handleClose}
                            >
                              Discard
                            </Button>
                            <Button variant="primary" onClick={RaiseRequest}>
                              Raise
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Attendance;
